<template>
<div class="taskIndex">
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
</div>
  

</template>

<script>

export default {

  data (){
    return{
      
    }
  }
};
</script>

<style lang="scss" scoped>
.taskIndex{
  overflow: hidden;
  height: 100%;
}
</style>